import { useEffect, useRef, useState } from "react";
import { assetUrl, getEthnicities } from "../Helpers";
import AgeSelector from "../Components/AgeSelector";

const ChooseEthnicity = ({ style, ethnicity, changeEthnicity, changeAge}) => {
    const wrapper = useRef(null);
    const [w, setW] = useState(false);
    const {baseUrl, ethnicities} = getEthnicities(style);
    useEffect(()=>{
        if(wrapper.current){
            setW(wrapper.current.offsetWidth);
        }
    },[]);
    const getClass = (item)=>{
        if(ethnicity===item.name){
            return 'relative rounded-3xl cursor-pointer selected-box-shadow';
        }
        if(ethnicity){
            return 'relative rounded-3xl cursor-pointer opacity-20';
        }
        return 'relative rounded-3xl cursor-pointer';
    }
    return (
        <div className="mt-10" ref={wrapper}>
            <div className="text-white w-full font-bold text-center text-md mb-6 lg:mb-12">
                Choose Ethnicity
            </div>
            <div className="">
                <div className="flex gap-4 flex-col lg:flex-row lg:justify-center mb-9 md:mb-0 ">
                    {ethnicities.map((item, index) => {
                        return (
                            <div key={index} className={getClass(item)} style={{backgroundImage:`url("${assetUrl(baseUrl + item.bg)}")`,backgroundSize: "cover",backgroundPosition: "center"}} onClick={()=>{
                                changeEthnicity(item.name);
                            }}>
                                <div className="flex justify-start lg:justify-center hover:scale-110 duration-300 origin-bottom pl-10 lg:pl-0">
                                    <img
                                        alt={item.name}
                                        className={`${ethnicity===item.name?'':w<1026?'':'hidden'} z-0 object-right -mr-8 lg:-mr-16 object-cover sm:object-contain cursor-pointer lg:rounded-3xl h-[150px] sm:w-[160px] sm:h-[200px]`}
                                        src={assetUrl(baseUrl + item['image-left'])} />
                                    <img
                                        alt={item.name}
                                        className="object-cover z-10 object-top sm:object-contain cursor-pointer lg:rounded-3xl h-[150px] sm:w-[160px] sm:h-[200px]"
                                        src={assetUrl(baseUrl + item['image-center'])} />
                                    <img
                                        alt={item.name}
                                        className={`${ethnicity===item.name?'':w<1026?'':'hidden'} z-0 object-left -ml-8 lg:-ml-16 object-cover sm:object-contain cursor-pointer lg:rounded-3xl h-[150px] sm:w-[160px] sm:h-[200px]`}
                                        src={assetUrl(baseUrl + item['image-right'])} />
                                </div>
                                <div className="absolute z-20 right-2 top-1/2 transform -translate-y-1/2 text-white bg-[#1C1C1C] bg-opacity-60 font-semibold lg:font-bold whitespace-nowrap leading-[15px] py-1.5 px-2.5 lg:py-3 lg:px-4 rounded-full lg:right-auto lg:left-1/2 lg:-translate-x-1/2 lg:top-auto lg:bottom-2 cursor-pointer">{item.name}</div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="text-white w-full hidden md:block font-bold text-center text-md mb-6 lg:mb-7 mt-12 lg:mt-16">
                Choose Age
            </div>
            <AgeSelector onAgeChange={changeAge} />
        </div>

    )
};
export default ChooseEthnicity;