import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';

const AgeSelector = ({ onAgeChange }) => {
    const [age, setAge] = useState(27);

    const debouncedOnAgeChange = useCallback(
        debounce((value) => {
            onAgeChange(value);
        }, 100),
        []
    );

    const handleChange = (event) => {
        const newAge = Number(event.target.value);
        setAge(newAge);
        debouncedOnAgeChange(newAge);
    };

    useEffect(() => {
        const rangeInput = document.getElementById('age');
        if (rangeInput) {
            rangeInput.style.background = `linear-gradient(to right, rgb(249, 108, 132) ${((age - 18) / (55 - 18)) * 100}%, rgb(28, 28, 28) ${((age - 18) / (55 - 18)) * 100}%)`;
        }
    }, [age]);

    return (
        <div className="w-full lg:w-[530px] mx-auto">
            <div className="flex flex-col relative mt-1">
                <div className="flex flex-0 justify-center mb-8">
                    <div className="p-2 bg-[#F96C84] text-white rounded-lg">
                        <span className="text-base text-white font-bold">{age}</span>
                        <span className="text-base text-white font-normal">Years</span>
                    </div>
                </div>
                <div className="flex flex-1 items-center justify-center">
                    <span className="text-white font-bold mr-[10px]">18+</span>
                    <input
                        type="range"
                        id="age"
                        name="age"
                        min={18}
                        max={55}
                        value={age}
                        onChange={handleChange}
                        className="w-full h-2 rounded-lg appearance-none cursor-pointer slider-thumb"
                        style={{
                            background: `linear-gradient(to right, rgb(249, 108, 132) ${((age - 18) / (55 - 18)) * 100}%, rgb(28, 28, 28) ${((age - 18) / (55 - 18)) * 100}%)`
                        }}
                        data-style="--slider-background: linear-gradient(to right, #F96C84 24%, #1c1c1c 24%);"
                    />
                    <span className="text-white font-bold ml-[10px]">55+</span>
                </div>
            </div>
        </div>
    );
};

export default AgeSelector;
