import { assetUrl, getStyles } from "../Helpers";

const ChooseStyle = ({style, changeStyle}) => {
    const styles = getStyles();
    return (
        <div className="mt-10">
            <div className="text-white w-full font-bold text-center text-md mb-5 lg:mb-14">
                Choose Style
            </div>
            <div className="flex justify-center gap-5 lg:gap-12 font-semibold">
                {Array.isArray(styles) && styles.map((item, index) => {
                    return (
                        <div className="inline-block relative rounded-3xl overflow-hidden" key={index} onClick={()=>{
                            changeStyle(item.name);
                        }}>
                            <div className="absolute bg-[#131313] border border-transparent rounded-[10px] inset-0 flex justify-center items-center hidden">
                                <div className="loader" />
                            </div>
                            <label htmlFor={item.name}>
                                <img
                                    alt={item.name}
                                    className={`object-cover object-top w-[190px] h-[378px] lg:w-[357px] lg:h-[530px] cursor-pointer rounded-3xl hover:scale-105 duration-300 overflow-hidden ${style == item.name ? 'selected' : style ? 'opacity-20' : ''}`}
                                    src={assetUrl(item.base + item.image)}
                                />
                                <div className="text-white font-bold bg-[#1C1C1C] bg-opacity-60 text-[20px] flex items-center justify-center whitespace-nowrap leading-[15px] absolute bottom-[6px] lg:bottom-[10px] left-1/2 transform -translate-x-1/2 py-3 px-4 rounded-full pill pill-inactive">
                                    {item.name}
                                </div>
                            </label>
                        </div>
                    )
                })}
            </div>
        </div>

    )
}
export default ChooseStyle;