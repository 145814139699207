// import audioWave from "../assets/img/audio_wave.svg";
// import { assetUrl, getRelationships, getVoices } from "../Helpers";
import { assetUrl, getRelationships } from "../Helpers";
const ChooseVoice = ({ style, changeVoice, voice, relationship }) => {
    // const audios = getVoices();
    const relationships = getRelationships();
    const getClass = (type, item) => {
        const base = 'flex flex-col items-center justify-center w-[88px] h-[112px] lg:w-[109.5px] lg:h-[135px] bg-[#1C1C1C] rounded-3xl cursor-pointer';
        const base2 = 'flex flex-col items-center justify-center w-[165px] h-[97px] lg:w-[109.5px] lg:h-[135px] bg-[#1C1C1C] rounded-3xl cursor-pointer';
        if(type === 'voice' && !voice){
            return base;
        }
        if(type === 'voice' && voice && voice !== item){
            return `${base} opacity-20`;
        }
        if(type === 'voice' && voice === item){
            return `${base} selected`
        }
        if(type === 'relation' && !relationship){
            return base2;
        }
        if(type === 'relation' && relationship && relationship !== item){
            return `${base2} opacity-20`;
        }
        if(type === 'relation' && relationship === item){
            return `${base2} selected`
        }
    }
    return (
        <div className="mt-10">
            {/* <div className="text-white w-full font-bold text-center text-md mb-5 lg:mb-[3.125rem]">
                Choose Voice
            </div>
            <div className="flex flex-row items-center justify-center gap-4 lg:gap-5">
                {audios.map((item, index) => {
                    return (
                        <div className={getClass('voice', item)} key={index} onClick={()=>{
                            changeVoice('voice', item);
                        }}>
                            <img
                                alt={`Voice ${index + 1}`}
                                className="object-contain cursor-pointer rounded-3xl w-8 lg:w-[2.9rem]"
                                src={audioWave}
                            />
                            <div className="text-white font-bold mt-2 lg:mt-2.5">Voice {index + 1}</div>
                            <div className="text-[#807E8B] text-sm font-normal">{item}</div>
                        </div>
                    )
                })}
            </div> */}
            <div className="text-white w-full font-bold text-center text-md mb-5 lg:mb-[3.125rem] mt-12 lg:mt-[4rem]">
                Choose Relationship
            </div>
            <div>
                <div className="flex flex-col lg:flex-row items-center justify-center gap-5">
                    {relationships.map((item, index) => {
                        return (
                            <div className="flex flex-row gap-4 justify-center" key={index}>
                                {item.map((subItem, subIndex) => {
                                    return (
                                        <div className={getClass('relation', subItem.name)} key={index + '-'+subIndex} onClick={()=>{
                                            changeVoice('relationship', subItem.name);
                                        }}>
                                            <div>
                                                <img
                                                    alt={subItem.name}
                                                    className="object-contain cursor-pointer rounded-3xl w-8  lg:w-[2.9rem] lg:h-full h-8"
                                                    src={assetUrl(subItem.icon)}
                                                />
                                            </div>
                                            <div className="text-white font-bold mt-2.5">{subItem.name}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
export default ChooseVoice;