import MagicWand from "../assets/img/magic-wand.svg";
const Footer = ({ step, changeStep, canNext, changeSave }) => {
    return (
        <div className="--footer">
            <div className="flex fixed lg:relative bottom-3 lg:bottom-auto left-4 lg:left-auto right-4 lg:right-auto items-center gap-4 justify-center mt-14">
                {step > 0 && (
                    <button className="text-white font-semibold py-2.5 px-5 bg-black-default border-2 border-[#2B2B2B] rounded-[10px]" onClick={() => {
                        changeStep(step - 1)
                    }}>
                        Previous
                    </button>
                )}
                {step < 5 && (
                    <button className={`w-72 lg:w-60 px-5 py-2.5 text-white font-semibold rounded-[10px] bg-gradient-to-b from-[#F96C84] to-[#FF869B] ${canNext() ? 'opacity-100' : 'opacity-50'}`} onClick={() => {
                        if (canNext()) {
                            changeStep(step + 1);
                        }
                    }}>
                        Next
                    </button>
                )}
                {step === 5 && (
                    <button className="flex justify-center bg-[linear-gradient(180deg,_#F96C84_0%,_#FF869B_100%)] border-2 border-[#FFB9C5] w-72 lg:w-60 px-5 py-2.5 text-white font-bold rounded-[10px]" onClick={()=>{
                        changeSave();//ends
                    }}>
                        Bring My AI to Life
                        <img
                            src={MagicWand}
                            className="object-cover w-5 ml-2.5"
                        />
                    </button>
                )}
            </div>
        </div>
    )
}
export default Footer;