import { useEffect, useRef, useState } from "react";
import { assetUrl, getCurrentBodyType, getCurrentEthnicity, getCurrentHairStyle, getCurrentRelationship, getCurrentStyleIndex, getCurrentStyleKey, getCurrentVoice, getStyles } from "../Helpers";
import audioWave from "../assets/img/audio_wave.svg";

const Summary = ({ step, style, ethnicity, age, bodyType, bodyBreast, bodyButt, hairStyle, hairColor, eyeColor, voice, relationship, save}) => {
    const [loading, setLoading] = useState(true);
    const timer = useRef();
    useEffect(() => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            setLoading(false);
        }, 800);
        return () => {
            clearTimeout(timer.current);
        }
    }, []);
    useEffect(()=>{
        if(save){
            setLoading(true);
            if(typeof window?.lander?.handler === 'function'){
                window?.lander?.handler({
                    style:style,
                    ethnicity:ethnicity, 
                    age:age, 
                    bodyType:bodyType, 
                    bodyBreast:bodyBreast, 
                    bodyButt:bodyButt, 
                    hairStyle:hairStyle, 
                    hairColor:hairColor, 
                    eyeColor:eyeColor, 
                    // voice:voice, 
                    relationship:relationship,
                })
            }
        }
    },[save]);
    const key = getCurrentStyleKey(style);
    const index = getCurrentStyleIndex(style);
    const styles = getStyles();
    const styleType = styles[index];
    const currentRelationship = getCurrentRelationship(relationship);
    const currentEthnicity = getCurrentEthnicity(style, ethnicity);
    // const currentVoice = getCurrentVoice(voice);
    const currentBodyType = getCurrentBodyType(style, 'type', bodyType); 
    const currentBodyButt = getCurrentBodyType(style, 'butt', bodyButt);
    const currentBodyBreast = getCurrentBodyType(style, 'breast', bodyBreast);
    const currentEyeColor = getCurrentHairStyle(style, 'eye-color', eyeColor);
    const currentHairStyle = getCurrentHairStyle(style, 'style', hairStyle);
    const currentHairColor = getCurrentHairStyle(style, 'color', hairColor);

    return (
        <div className="mt-10 max-w-3xl mx-auto">
            {loading && (
                <div className="fixed top-0 left-0 z-50 w-full h-full flex justify-center items-center bg-black-default bg-opacity-60 backdrop-blur-xl" >
                    <div className="loader" />
                </div>
            )}
            <div className="text-white w-full font-bold text-center text-md mb-6 lg:mb-[3.125rem]">
                Summary
            </div>
            <div className="flex flex-col">
                <div className="hidden lg:flex flex-1 gap-5 mb-5">
                    <div className="flex-1 relative rounded-3xl overflow-hidden">
                        <img
                            src={assetUrl(styleType.base + styleType.image)}
                            alt="characterStyle"
                            className="mx-auto object-cover object-top lg:min-w-[400px] lg:h-[450px] hover:scale-105 transition-transform duration-300 transform overflow-hidden"
                            decoding="sync"
                            j8ldk7p29=""
                        />
                        <div className="text-white font-bold bg-[#1C1C1C] bg-opacity-60 text-sm lg:text-[16px] flex items-center justify-center whitespace-nowrap leading-[15px] absolute bottom-[6px] lg:bottom-[10px] left-1/2 transform -translate-x-1/2 py-3 px-4 rounded-full pill pill-inactive">
                            {styleType.name}
                        </div>
                    </div>
                    <div className="flex-1 flex flex-col gap-6">
                        <div className="flex flex-1 flex-col justify-center relative bg-[#1C1C1C] rounded-3xl">
                            <img
                                src={assetUrl(currentRelationship.icon)}
                                alt="characterRelationship"
                                className="mx-auto w-[60px] h-[68px] mb-4"
                                decoding="sync"
                            />
                            <div className="text-white text-[16px] font-bold flex items-center justify-center whitespace-nowrap leading-[15px]">
                                {currentRelationship.name}
                            </div>
                        </div>
                        <div className="flex-1 flex flex-row gap-5">
                            <div
                                className="relative flex flex-1 rounded-3xl"
                                style={{
                                    backgroundSize: "cover",
                                    backgroundPosition: "center center",
                                    backgroundImage:`url("${assetUrl(currentEthnicity.baseUrl + currentEthnicity.ethnicity['bg'])}")`
                                }}
                            >
                                <img
                                    src={assetUrl(currentEthnicity.baseUrl + currentEthnicity.ethnicity['image-center'])}
                                    alt="origin"
                                    className="self-end object-contain cursor-pointer rounded-3xl lg:w-[160px] lg:h-[200px] hover:scale-110 transition-transform duration-300 transform origin-bottom object-bottom"
                                    decoding="sync"
                                />
                                <div className="text-white font-semibold lg:font-bold bg-[#1C1C1C] bg-opacity-60 text-[16px] flex items-center justify-center whitespace-nowrap leading-[15px] absolute bottom-[6px] lg:bottom-[10px] left-1/2 transform -translate-x-1/2 py-3 px-4 rounded-full">
                                    {currentEthnicity.ethnicity.name}
                                </div>
                            </div>
                            {/* <div className="flex flex-1 flex-col items-center justify-center bg-[#1C1C1C] rounded-3xl cursor-pointer">
                                <div>
                                    <img
                                        alt="Voice"
                                        className="object-contain cursor-pointer rounded-3xl"
                                        src={audioWave}
                                    />
                                </div>
                                <div className="text-white font-bold mt-4">Voice {currentVoice}</div>
                                <div className="text-[#807E8B] font-sm font-normal">{voice}</div>
                            </div> */}
                            <div className="flex flex-1 flex-col items-center justify-center bg-[#1C1C1C] rounded-3xl">
                                <div className="font-bold text-3xl text-white">{age}</div>
                                <div className="font-normal text-[15px] text-[#807E8B]">Years</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex lg:hidden gap-5 mb-5">
                    <div className="relative flex flex-1 rounded-3xl">
                        <img
                            src={assetUrl(styleType.base + styleType.image)}
                            alt="characterStyle"
                            className="object-cover cursor-pointer rounded-3xl mx-auto"
                            decoding="sync"
                            j8ldk7p29=""
                        />
                        <div className="text-white bg-[#1C1C1C] bg-opacity-60 text-sm flex items-center justify-center whitespace-nowrap leading-[15px] absolute bottom-[6px] lg:bottom-[10px] left-1/2 transform -translate-x-1/2 py-3 px-4 rounded-full">
                            {styleType.name}
                        </div>
                    </div>
                    <div
                        className="relative flex flex-1 rounded-3xl"
                        style={{
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            backgroundImage:`url("${assetUrl(currentEthnicity.baseUrl + currentEthnicity.ethnicity['bg'])}")`
                        }}
                    >
                        <img
                            src={assetUrl(currentEthnicity.baseUrl + currentEthnicity.ethnicity['image-center'])}
                            alt="origin"
                            className="object-cover cursor-pointer rounded-3xl mx-auto object-bottom w-full"
                            decoding="sync"
                        />
                        <div className="text-white bg-[#1C1C1C] bg-opacity-60 text-sm flex items-center justify-center whitespace-nowrap leading-[15px] absolute bottom-[6px] lg:bottom-[10px] left-1/2 transform -translate-x-1/2 py-3 px-4 rounded-full">
                        {currentEthnicity.ethnicity.name}
                        </div>
                    </div>
                    <div className="flex-1 flex flex-col  justify-between items-center gap-5">
                        <div className="flex flex-1 flex-col justify-center relative bg-[#1C1C1C] rounded-3xl w-full">
                            <img
                                src={assetUrl(currentRelationship.icon)}
                                alt="characterRelationship"
                                className="mx-auto w-10 mb-2 pt-2"
                                decoding="sync"
                            />
                            <div className="text-white text-[16px] font-bold flex items-center justify-center whitespace-nowrap leading-[15px] pb-5">
                                {currentRelationship.name}
                            </div>
                        </div>
                        {/* <div className="flex flex-1 flex-col items-center justify-center bg-[#1C1C1C] rounded-3xl w-full">
                            <div>
                                <img
                                    alt="Voice 3"
                                    className="object-contain rounded-3xl w-10 pt-2"
                                    src={audioWave}
                                />
                            </div>
                            <div className="text-white font-bold mt-2">Voice {currentVoice}</div>
                            <div className="text-[#807E8B] font-sm font-normal">{voice}</div>
                        </div> */}
                    </div>
                </div>
                <div className="flex lg:hidden flex-1 flex-col items-center justify-center bg-[#1C1C1C] rounded-3xl mb-5 py-2">
                    <div className="flex font-bold text-3xl text-white">{age}</div>
                    <div className="font-normal text-[15px] text-[#807E8B]">Years</div>
                </div>
                <div className="flex flex-1 flex-col lg:flex-row items-center justify-between gap-4">
                    <div className="flex flex-1 items-center justify-between gap-5 lg:gap-0">
                        <div className="relative rounded-3xl overflow-hidden w-[112px] h-[112px]">
                            <div className="hover:scale-105 transition-transform duration-300 transform overflow-hidden">
                                <img
                                    src={assetUrl(currentBodyType.baseUrl + currentBodyType.bodyType.image)}
                                    alt="type"
                                    className="object-contain cursor-pointer rounded-3xl w-full h-full"
                                    decoding="sync"
                                />
                            </div>
                            <div className="absolute flex items-center justify-center whitespace-nowrap bottom-2 left-1/2 right-1/2">
                                <div className="text-white font-bold text-sm lg:text-[16px] leading-[15px] bg-[#1C1C1C] bg-opacity-60 rounded-full py-1.5 lg:py-1.5 px-1.5 lg:px-3">
                                    {currentBodyType.bodyType.name}
                                </div>
                            </div>
                        </div>
                        <div className="relative rounded-3xl overflow-hidden w-[112px] h-[112px]">
                            <div className="hover:scale-105 transition-transform duration-300 transform overflow-hidden">
                                <img
                                    src={assetUrl(currentBodyButt.baseUrl + currentBodyButt.bodyType.image)}
                                    alt="short"
                                    className="object-contain cursor-pointer rounded-3xl w-full h-full"
                                    decoding="sync"
                                />
                            </div>
                            <div className="absolute flex items-center justify-center whitespace-nowrap bottom-2 left-1/2 right-1/2">
                                <div className="text-white font-bold text-sm lg:text-[16px] leading-[15px] bg-[#1C1C1C] bg-opacity-60 rounded-full py-1.5 lg:py-1.5 px-1.5 lg:px-3">
                                    {currentBodyButt.bodyType.name}
                                </div>
                            </div>
                        </div>
                        <div className="relative rounded-3xl overflow-hidden w-[112px] h-[112px]">
                            <div className="hover:scale-105 transition-transform duration-300 transform overflow-hidden">
                                <img
                                    src={assetUrl(currentBodyBreast.baseUrl + currentBodyBreast.bodyType.image)}
                                    alt="short"
                                    className="object-contain cursor-pointer rounded-3xl w-full h-full"
                                    decoding="sync"
                                />
                            </div>
                            <div className="absolute flex items-center justify-center whitespace-nowrap bottom-2 left-1/2 right-1/2">
                                <div className="text-white font-bold text-sm lg:text-[16px] leading-[15px] bg-[#1C1C1C] bg-opacity-60 rounded-full py-1.5 lg:py-1.5 px-1.5 lg:px-3">
                                    {currentBodyBreast.bodyType.name}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-1 items-center justify-between gap-5 lg:gap-0">
                        <div className="relative rounded-3xl overflow-hidden w-[115px] h-[118px]">
                            <div className="hover:scale-105 transition-transform duration-300 transform overflow-hidden">
                                <img
                                    src={assetUrl(currentEyeColor.baseUrl + currentEyeColor.style.image)}
                                    alt="short"
                                    className="object-contain cursor-pointer rounded-3xl w-full h-full"
                                    decoding="sync"
                                />
                            </div>
                            <div className="absolute flex items-center justify-center whitespace-nowrap bottom-2 left-1/2 right-1/2">
                                <div className="text-white font-bold text-sm lg:text-[16px] leading-[15px] bg-[#1C1C1C] bg-opacity-60 rounded-full py-1.5 lg:py-1.5 px-1.5 lg:px-3">
                                    {currentEyeColor.style.name}
                                </div>
                            </div>
                        </div>
                        <div className="relative rounded-3xl overflow-hidden w-[112px] h-[112px]">
                            <div className="hover:scale-105 transition-transform duration-300 transform overflow-hidden">
                                <img
                                    src={assetUrl(currentHairStyle.baseUrl + currentHairStyle.style.image)}
                                    alt="short"
                                    className="object-contain cursor-pointer rounded-3xl w-full h-full"
                                    decoding="sync"
                                />
                            </div>
                            <div className="absolute flex items-center justify-center whitespace-nowrap bottom-2 left-1/2 right-1/2">
                                <div className="text-white font-bold text-sm lg:text-[16px] leading-[15px] bg-[#1C1C1C] bg-opacity-60 rounded-full py-1.5 lg:py-1.5 px-1.5 lg:px-3">
                                    {currentHairStyle.style.name}
                                </div>
                            </div>
                        </div>
                        <div className="relative rounded-3xl overflow-hidden w-[112px] h-[112px]">
                            <div className="hover:scale-105 transition-transform duration-300 transform overflow-hidden">
                                <img
                                    src={assetUrl(currentHairColor.baseUrl + currentHairColor.style.image)}
                                    alt="short"
                                    className="object-contain cursor-pointer rounded-3xl w-full h-full"
                                    decoding="sync"
                                />
                            </div>
                            <div className="absolute flex items-center justify-center whitespace-nowrap bottom-2 left-1/2 right-1/2">
                                <div className="text-white font-bold text-sm lg:text-[16px] leading-[15px] bg-[#1C1C1C] bg-opacity-60 rounded-full py-1.5 lg:py-1.5 px-1.5 lg:px-3">
                                    {currentHairColor.style.name}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Summary;