import { assetUrl, getCurrentStyleKey, getHairOptions, getHairTypes } from "../Helpers";

const ChooseHairStyle = ({ style, changeHair, hairStyle, hairColor, eyeColor }) => {
    const { baseUrl, types } = getHairTypes(style);
    const key = getCurrentStyleKey(style);
    const getClass = (item, subItem) => {
        const baseClass = 'rounded-[26px] lg:rounded-[30px] transform transition-transform hover:scale-105 duration-300 overflow-hidden border-[4.5px] border-transparent';
        const selectedClass = `${baseClass} selected`;
        const nonSelectedClass = `${baseClass} opacity-20`;
        const typeMapping = {
            'style': hairStyle,
            'color': hairColor,
            'eye-color': eyeColor
        };
        const selectedValue = typeMapping[item.type];
        if (!selectedValue) {
            return baseClass;
        }
        return subItem.name === selectedValue ? selectedClass : nonSelectedClass;
    };
    return (
        <div className="mt-10">
            {types.map((item, index) => {
                const options = getHairOptions()[item.type] || [];
                return (
                    <div className="mb-5 mt-5" key={index}>
                        <div className="text-white w-full font-bold text-center text-md mb-6 lg:mb-4">
                            Choose {item.name}
                        </div>
                        <div className="flex gap-2 lg:gap-4 justify-center flex-col lg:flex-row">
                            <div className="flex flex-row gap-4 justify-center">
                                {options.map((subItem, subIndex) => {
                                    if(! subItem.allow.includes(key)){
                                        return null;
                                    }
                                    return (
                                        <div className="relative rounded-3xl overflow-hidden" key={subItem.name + subIndex} onClick={()=>{
                                            changeHair(item.type, subItem.name);
                                        }}>
                                            <div className={getClass(item, subItem)}>
                                                <img
                                                    alt={subItem.name}
                                                    className="object-contain cursor-pointer rounded-3xl"
                                                    src={assetUrl(baseUrl + item.type + '/' + subItem.image)}
                                                />
                                                <div className="absolute flex items-center justify-center whitespace-nowrap bottom-2 left-1/2 right-1/2 cursor-pointer">
                                                    <div className="text-white font-bold text-sm lg:text-[16px] leading-[15px] bg-[#1C1C1C] bg-opacity-60 rounded-full py-1.5 lg:py-1.5 px-1.5 lg:px-3">
                                                    {subItem.name}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                )
            })}


        </div>
    )
}
export default ChooseHairStyle;