import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

if (document.contains(document.getElementById('lander'))) {
    const root = ReactDOM.createRoot(document.getElementById('lander'));
    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
}