import root from 'react-shadow';
// eslint-disable-next-line import/no-webpack-loader-syntax
import css from '!!css-loader?{"sourceMap":false,"exportType":"string"}!./App.css';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
import { useState } from 'react';
import ChooseHairStyle from './Templates/ChooseHairStyle';
import ChooseStyle from './Templates/ChooseStyle';
import ChooseEthnicity from "./Templates/ChooseEthnicity";
import ChooseBodyType from './Templates/ChooseBodyType';
import ChooseVoice from './Templates/ChooseVoice';
import Summary from "./Templates/Summary";
function App() {
    const [step, setStep] = useState(0);
    const [style, setStyle] = useState(false);
    const [ethnicity, setEthnicity] = useState(false);
    const [age, setAge] = useState(27);
    const [bodyType, setBodyType] = useState(false);
    const [bodyBreast, setBodyBreast] = useState(false);
    const [bodyButt, setBodyButt] = useState(false);
    const [hairStyle, setHairStyle] = useState(false);
    const [hairColor, setHairColor] = useState(false);
    const [eyeColor, setEyeColor] = useState(false);
    // const [voice, setVoice] = useState(false);
    const [relationship, setRelationship] = useState(false);
    const [save, setSave] = useState(false);
    const changeStep = (_step) =>{
        setStep(_step);
    }
    const changeStyle = (_style) =>{
        setStyle(_style);
    }
    const changeEthnicity = (_ethnicity) =>{
        setEthnicity(_ethnicity);
    }
    const changeAge = (_age) =>{
        setAge(_age);
    }
    const changeBody = (_type, _value) =>{
        if(_type === 'type'){
            setBodyType(_value);
        }
        if(_type === 'breast'){
            setBodyBreast(_value);
        }
        if(_type === 'butt'){
            setBodyButt(_value);
        }
    }
    const changeHair = (_type, _value) =>{
        if(_type === 'style'){
            setHairStyle(_value);
        }
        if(_type === 'color'){
            setHairColor(_value);
        }
        if(_type === 'eye-color'){
            setEyeColor(_value);
        }
    }
    const changeVoice = (_type, _value)=>{
        // if(_type === 'voice'){
        //     setVoice(_value);
        // }
        if(_type === 'relationship'){
            setRelationship(_value);
        }
    }
    const canNext = () => {
        const conditions = [
            step === 0 && !!style,
            step === 1 && !!ethnicity,
            step === 2 && !!bodyType && !!bodyBreast && !!bodyButt,
            step === 3 && !!hairStyle && !!hairColor && !!eyeColor,
            step === 4 && !!relationship,
            // step === 4 && !!voice && !!relationship,
        ];
        return conditions[step] || false;
    };
    return (
        <root.div className='h-full'>
            <link 
                href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" 
                rel="stylesheet" 
            />
            <style type="text/css">{css}</style>
            <div className="font-poppins bg-black-default w-full min-h-screen pb-10 main-wrapper">
                <Header step={step} />
                <div className="px-4 lg:px-0 mb-16 lg:mb-0">
                    {step===0&&<ChooseStyle style={style} changeStyle={changeStyle} />}
                    {step===1&&<ChooseEthnicity style={style} ethnicity={ethnicity} changeEthnicity={changeEthnicity} changeAge={changeAge}/>}
                    {step===2&&<ChooseBodyType style={style} changeBody={changeBody} bodyType={bodyType} bodyBreast={bodyBreast} bodyButt={bodyButt}/>}
                    {step===3&&<ChooseHairStyle style={style} changeHair={changeHair} hairStyle={hairStyle} hairColor={hairColor} eyeColor={eyeColor}/>}
                    {step===4&&<ChooseVoice style={style} changeVoice={changeVoice} voice={null} relationship={relationship} />}
                    {step===5&&<Summary step={step} style={style} ethnicity={ethnicity} age={age} bodyType={bodyType} bodyBreast={bodyBreast} bodyButt={bodyButt} hairStyle={hairStyle} hairColor={hairColor} eyeColor={eyeColor} voice={null} relationship={relationship} save={save} />}
                </div>
                <Footer step={step} changeStep={changeStep} canNext={canNext} changeSave={()=>{
                    setSave(true);
                }} />
            </div>
        </root.div>
    );
}

export default App;
